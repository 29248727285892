import { Button, Grid, TextareaAutosize } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import UpLoadImageVideo from './UpLoadImageVideo';
import CustomInput from './CustomInput';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useAppDispatch } from '@/store/store';
import { useSelector } from 'react-redux';
import Spinner from '@/components/common/Spinner';
import * as showDialog from '@/utils/showDialog';
import { useRouter } from 'next/router';

import {
  getUserInfoSelector,
  isLoadingRequestSelector,
  requestProductSubmit,
  submitRequestSuccessSelector,
  errorRequestSelector,
  clearError,
  clearResult,
  clearSuccess,
} from '@/store/slices/requestProductSlice';

interface Props {
  onClose: () => void;
}

export default function RequestProductForm({ onClose }: Props) {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const getUserInfo = useSelector(getUserInfoSelector);
  const isLoadingRequest = useSelector(isLoadingRequestSelector);
  const submitRequestSuccess = useSelector(submitRequestSuccessSelector);
  const errorRequest = useSelector(errorRequestSelector);
  const [imageList, setImageList] = useState<File[]>([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    productDetail: string;
    nameLastName: string;
    email: string;
    mobile: string;
  }>();

  useEffect(() => {
    if (errorRequest) {
      showDialog
        .showErrorWithOkButton({
          text: `${errorRequest}`,
        })
        .then(() => {
          dispatch(clearError());
        });
      return;
    }
    if (submitRequestSuccess) {
      showDialog.showMessageWithButton({
        icon: 'success',
        text: t('thankyouforcontacting'),
        primaryButton: {
          name: t('continueshopping'),
          callBack: () => {
            dispatch(clearSuccess());
            dispatch(clearResult());
            router.push(`/${router.locale}`);
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitRequestSuccess, dispatch, t, errorRequest]);

  const handleImageUpload = (image: File[]) => {
    setImageList(image);
  };

  const onSubmit: SubmitHandler<{
    productDetail: string;
    nameLastName: string;
    email: string;
    mobile: string;
  }> = (data) => {
    const sendData = {
      detail: data.productDetail,
      name: data.nameLastName,
      email: data.email,
      mobile: data.mobile,
      images: imageList,
    };

    dispatch(
      requestProductSubmit({
        recapchaToken: '',
        requestData: sendData,
      }),
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="h-full">
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <div className="text-2xl font-semiblold">
            {t('whatareyoulookingfor')}
          </div>
          <div className="pt-2 text-sm text-[#767676] font-semibold">
            <div
              className={`text-xs leading-4 ${
                errors.productDetail && 'text-redPrice'
              }`}
            >
              {t('productdetails')}*
            </div>
            <TextareaAutosize
              className={`border w-full px-2 py-1 rounded text-sm  ${
                errors.productDetail && 'border-redPrice'
              }`}
              minRows={5}
              {...register('productDetail', {
                required: `${t('pleasefill')} ${t('productdetails')}`,
              })}
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={6}>
          <div className="text-2xl font-semiblold">
            {t('contactinformation')}
          </div>
          <div className="pt-2 text-sm text-[#767676] font-semibold">
            <div className="my-2">
              <CustomInput
                label={`${t('profile:namelastname')}`}
                defaultValue={getUserInfo?.name}
                error={errors.nameLastName}
                required
                register={register('nameLastName', {
                  required: `${t('pleasefill')} ${t('profile:namelastname')}`,
                })}
              />
            </div>
            <div className="my-2">
              <CustomInput
                label={`${t('profile:email')}`}
                defaultValue={getUserInfo?.email}
                error={errors.email}
                required
                register={register('email', {
                  required: `${t('pleasefill')} ${t('profile:email')}`,
                })}
              />
            </div>
            <div className="my-2">
              <CustomInput
                label={`${t('profile:mobilenumber')}`}
                defaultValue={getUserInfo?.mobile}
                maxLength={10}
                regex={/[^0-9]/g}
                error={errors.mobile}
                required
                register={register('mobile', {
                  required: `${t('pleasefill')} ${t('profile:mobilenumber')}`,
                })}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={12}>
          <div>
            <span className="text-sm text-[#767676] font-semibold">
              {t('attachuploadpicture')}
            </span>
            <UpLoadImageVideo
              isImage={true}
              maxImage={6}
              imageCallBack={(image) => handleImageUpload(image)}
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={12}>
          <div className="flex justify-center gap-2">
            {isLoadingRequest ? (
              <Button
                className="font-bold bg-primary text-white px-2 w-1/4"
                sx={{
                  color: 'white',
                  backgroundColor: '#B8292F',
                  fontWeight: 'bold',
                  px: 2,
                  width: '25%',
                  '&:hover': {
                    backgroundColor: '#B8292F',
                  },
                }}
              >
                <Spinner size={24} />
              </Button>
            ) : (
              <Button
                className="font-bold bg-primary text-white px-2 w-1/4"
                sx={{
                  color: 'white',
                  backgroundColor: '#B8292F',
                  fontWeight: 'bold',
                  px: 2,
                  width: '25%',
                  '&:hover': {
                    backgroundColor: '#B8292F',
                  },
                }}
                type="submit"
              >
                {t('submitinformation')}
              </Button>
            )}

            <Button
              className="font-bold bg-[#999] text-white px-2 w-1/4"
              sx={{
                color: 'white',
                backgroundColor: '#999',
                fontWeight: 'bold',
                px: 2,
                width: '25%',
                '&:hover': {
                  backgroundColor: '#999',
                },
              }}
              onClick={() => onClose()}
            >
              {t('cancel')}
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
}
