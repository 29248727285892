import React from 'react';
import { FieldError } from 'react-hook-form';

interface Props {
  label?: string;
  placeholder?: string;
  defaultValue?: string;
  value?: string;
  textHelper?: string;
  error?: FieldError | undefined;
  required?: boolean;
  maxLength?: number;
  register?: any;
  regex?: RegExp;
  disabled?: boolean;
}

export default function CustomInput({
  label,
  defaultValue,
  value,
  error,
  required,
  maxLength,
  register,
  regex,
  disabled,
  placeholder,
}: Props) {
  return (
    <div className="w-full">
      {label && (
        <div className={`text-xs leading-4 ${error && 'text-redPrice'}`}>
          {label}
          {required && '*'}
        </div>
      )}
      <input
        placeholder={placeholder}
        type="text"
        className={`border w-full px-2 py-1 rounded text-sm ${
          disabled && 'bg-grayLight'
        } ${error && 'border-redPrice'}`}
        disabled={disabled}
        value={value}
        onInput={(e: any) => {
          if (regex) {
            e.target.value = e.target.value.replace(regex, '');
          }
        }}
        maxLength={maxLength}
        defaultValue={defaultValue}
        {...register}
      />
      {/* <div className="text-xs text-redPrice ms-0.5">{error?.message}</div> */}
    </div>
  );
}
