import React, { useState } from 'react';
import Image from 'next/image';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'next-i18next';
import RequestProductForm from '../common/RequestProductForm';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@/store/store';
import {
  getUserInfoQuery,
  isLoadingUserInfoSelector,
} from '@/store/slices/requestProductSlice';
import Spinner from '@/components/common/Spinner';

interface Props {
  keyword?: string;
}

export default function NotFound({ keyword }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isLoadingUserInfo = useSelector(isLoadingUserInfoSelector);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const handleOpenForm = (e: any) => {
    e.preventDefault();
    dispatch(getUserInfoQuery()).then(() => {
      setOpenForm(true);
    });
  };

  return (
    <div>
      <div className="py-5 px-2 md:px-0">
        <div className="pt-5 pb-10 px-2 border rounded-sm shadow-md w-full lg:w-3/5 mx-auto relative bg-white">
          {openForm ? (
            <RequestProductForm onClose={() => setOpenForm(false)} />
          ) : (
            <>
              <div className="block lg:flex justify-center">
                <Image
                  src="/images/p_thai/thai_sorry_smile.png"
                  alt="not found"
                  className="hidden lg:block my-auto"
                  width={88}
                  height={186}
                />
                <div className="ml-4 text-center">
                  <div className="hidden lg:block">
                    {keyword ? (
                      <>
                        <span className="text-base">{t('sorry')}!</span>{' '}
                        <span className="font-semibold">
                          {`"`}
                          {keyword}
                          {`"`}
                        </span>{' '}
                        {t('didnotmatch')}
                      </>
                    ) : (
                      <>
                        <span className="font-semibold text-base">
                          {t('sorry')}!
                        </span>{' '}
                        {t('yoursearchdidnotmatch')}
                      </>
                    )}
                  </div>
                  <div className="flex lg:hidden justify-center pb-3">
                    <Image
                      src="/images/p_thai/thai_sorry_smile.png"
                      alt="not found"
                      className="my-auto"
                      width={32}
                      height={68}
                    />
                    <div className="ml-3">
                      <div className="font-semibold text-2xl">
                        {t('sorry')}!
                      </div>{' '}
                      <div>{t('yoursearchdidnotmatch')}</div>
                    </div>
                  </div>
                  <hr className="border-dashed border-grayDark" />
                  <div className="py-5">
                    <span>{t('pleasetrymorecommonword')}</span>
                    <br />
                    <span className="font-semibold">{t('orifyouwant')}</span>
                    <br />
                    <span className="font-semibold text-3xl">
                      {t('wecanhelpyoufindproduct')}
                    </span>
                    <br />
                    <span className="font-semibold">
                      {t('youarelookingfor')}
                    </span>
                  </div>
                  <hr className="border-dashed border-grayDark" />
                </div>
              </div>
              <div className="my-2 px-2 lg:px-12">
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={12} lg={4}>
                    <div className="mx-2 text-center">
                      <div className="flex justify-center">
                        <Image
                          src="/images/svg/notfound/search_icon.svg"
                          className="my-auto"
                          alt="search"
                          width={41}
                          height={40}
                        />
                        <div className="ml-2 leading-none font-semibold">
                          <span className="text-2xl">{t('letus')}</span>
                          <br />
                          <span>{t('finditforyou')}</span>
                        </div>
                      </div>
                      {isLoadingUserInfo ? (
                        <Button
                          className="bg-primary text-white w-1/2 lg:w-full py-1"
                          sx={{
                            backgroundColor: '#B8292F',
                            color: 'white',
                            '&:hover': { backgroundColor: '#B8292F' },
                            width: '100%',
                            py: 0,
                          }}
                        >
                          <Spinner size={24} />
                        </Button>
                      ) : (
                        <Button
                          className="bg-primary text-white w-1/2 lg:w-full py-0"
                          sx={{
                            backgroundColor: '#B8292F',
                            color: 'white',
                            '&:hover': { backgroundColor: '#B8292F' },
                            width: '100%',
                            py: 0,
                          }}
                          onClick={handleOpenForm}
                        >
                          <span className="font-bold flex">
                            {t('clickcontactus')}
                            <Image
                              src="/images/svg/chevron_right_white.svg"
                              className="my-auto ml-2"
                              alt="next"
                              width={8}
                              height={12}
                            />
                          </span>
                        </Button>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <div className="mx-2 text-center">
                      <div className="flex justify-center">
                        <Image
                          src="/images/svg/notfound/call_center.svg"
                          className="my-auto"
                          alt="call center"
                          width={56}
                          height={38}
                        />

                        <div className="ml-2 leading-none font-semibold">
                          <span className="text-2xl">{t('callshop')}</span>
                          <br />
                          <span>{t('inonecall')}</span>
                        </div>
                      </div>
                      <a href="tel:1308">
                        <Button
                          className="bg-primary text-white w-1/2 lg:w-full py-0"
                          sx={{
                            backgroundColor: '#B8292F',
                            color: 'white',
                            '&:hover': { backgroundColor: '#B8292F' },
                            width: '100%',
                            py: 0,
                          }}
                        >
                          <span className="font-bold">
                            {t('telcallcenter')}
                          </span>
                        </Button>
                      </a>
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <div className="mx-2 text-center">
                      <div className="flex justify-center">
                        <Image
                          src="/images/svg/notfound/message_icon.svg"
                          className="my-auto"
                          alt="message"
                          width={56}
                          height={38}
                        />

                        <div className="ml-2 leading-none font-semibold">
                          <span className="text-2xl">{t('fullshopping')}</span>
                          <br />
                          <span>{t('endinchat')}</span>
                        </div>
                      </div>
                      <a href="https://bit.ly/2X7bAuK" target="_blank">
                        <Button
                          className="bg-primary text-white w-1/2 lg:w-full py-0"
                          sx={{
                            backgroundColor: '#B8292F',
                            color: 'white',
                            '&:hover': { backgroundColor: '#B8292F' },
                            width: '100%',
                            py: 0,
                          }}
                        >
                          <span className="font-bold flex">
                            {t('addfriend')}
                            <Image
                              src="/images/svg/chevron_right_white.svg"
                              className="my-auto ml-2"
                              alt="next"
                              width={8}
                              height={12}
                            />
                          </span>
                        </Button>
                      </a>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
